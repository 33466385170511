import React from 'react';
import { Image, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { Hoverable } from 'react-native-web-hover';
import { MFullWidthButton } from 'menupass-ui';
import { DatatableBodyTypes } from './DatatableBody.types';
import { datatableBodyStyles } from './DatatableBody.styles';
import { colors, Images } from '@/consts';
import { ColumnComponents } from '@/components/datatable/header/datatableHeader.types';
import { Switch } from '@/components/switch/Switch';
import locale from '@/internationalization';

export const DatatableBody = ({
  height,
  items,
  headers,
  onSwitchChange,
  onEditPress,
  onDetalhes,
  access,
}: DatatableBodyTypes) => {
  const tableHeight = height ? { height } : {};
  const getTextStyle = (type: string) => {
    const stylesMap: Record<string, any> = {
      'Chargeback': datatableBodyStyles.typeofStringDiff,
      'Em Disputa': datatableBodyStyles.typeofStringDiff2,
    };
    
    return stylesMap[type] || datatableBodyStyles.typeofString;
  };
  return (
    <ScrollView style={[datatableBodyStyles.container, tableHeight]}>
      {items?.map((item: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <View key={`${index}_body`} style={datatableBodyStyles.row}>
          {headers?.map(header => (
            <View
              style={[datatableBodyStyles.column, { width: header?.width }]}
              key={
                item.id ? `${item.id}_${header.key}` : `${header.key}_${index}`
              }>
              {header.renderComponent &&
                header.renderComponent === ColumnComponents.Switch && (
                  <Switch
                    label={
                      <Text style={datatableBodyStyles.typeofString}>
                        {!!item[header.key]?.label && item[header.key]?.label}
                      </Text>
                    }
                    backgroundInactive={colors.datatableBody.switchDisabled}
                    backgroundActive={colors.datatableBody.switchEnabled}
                    circleColorInactive={colors.datatableBody.thumb}
                    circleColorActive={colors.datatableBody.thumb}
                    onValueChange={(val: boolean) =>
                      onSwitchChange && onSwitchChange(val, item.id)
                    }
                    value={item[header.key]?.value}
                  />
                )}
              {header.renderComponent &&
                header.renderComponent === ColumnComponents.Edit && (
                  <TouchableOpacity
                    style={datatableBodyStyles.editBtn}
                    onPress={() => onEditPress && onEditPress(item.id)}>
                    <View style={datatableBodyStyles.imageWrapper}>
                      <img
                        src={Images.EditIcon}
                        alt={locale.t('imagesAlt.edit')}
                      />
                    </View>
                  </TouchableOpacity>
                )}
              {header.renderComponent &&
                header.renderComponent === ColumnComponents.Image && (
                  <Image
                    source={{ uri: item[header.key] }}
                    style={datatableBodyStyles.imageAsset}
                  />
                )}
              {header.renderComponent &&
                header.renderComponent === ColumnComponents.Password && (
                  <Hoverable>
                    {({ hovered }) => (
                      <Text style={datatableBodyStyles.typeofStringHover}>
                        {hovered
                          ? !!item[header.key] && item[header.key]
                          : '****'}
                      </Text>
                    )}
                  </Hoverable>
                )}
                 {!header.renderComponent && (
               <Text style={getTextStyle(item.type)}>
               {Array.isArray(item[header.key])
                 ? item[header.key].join(', ')
                 : item[header.key]}
             </Text>
              )}
              {header.renderComponent &&
                header.renderComponent === ColumnComponents.Detalhes &&
                item.isDetalhes && (
                  <TouchableOpacity
                    onPress={() => onDetalhes && onDetalhes(item.id)}>
                    <Text style={datatableBodyStyles.typeofStringDetalhes}>
                      ver mais
                    </Text>
                  </TouchableOpacity>
                )}
              {header.renderComponent &&
                header.renderComponent === ColumnComponents.access && (
                  <MFullWidthButton
                    label={locale.t('access')}
                    onPress={() => access && access(item.id || '')}
                    disabled={!item.id}
                    height={36}
                  />
                )}
            </View>
          ))}
        </View>
      ))}
    </ScrollView>
  );
};

export default DatatableBody;
