import { formatNumber } from 'react-native-currency-input';
import { useEffect, useState } from 'react';
import { useGetAdmin, useGetAdminId } from '../../api/admin';
import { useGetEntity } from '../../api/entities';
import { ExtractTransaction, Order } from '@/api/extract/dto';
import {
  currencyFormatFloat,
  currencyFormatFloatNagative,
} from '@/utils/strUtils';
import { formatOptions } from '@/utils/currencyMask';
import { formatDateLocal } from '@/utils/date';
import { useGetClientOrders } from '@/api/order';
import { useGetClientTransactions } from '@/api/extract';
import { useGetClientById, useGetEntityClients } from '@/api/client';
import { LimitsVirtualCardDto } from '@/api/client/dto';
import { productRole } from '@/types/productRoles';
import { useUpdateVirtualCard } from '@/api/vitualCard';
import locale from '@/internationalization';

export const useFinancExtracts = (entity: string) => {
  const [isActiveInactive, setIsActiveInactive] = useState<productRole>(
    productRole.Activo,
  );
  const [isActiveRecurrentRecharge, setIsActiveRecurrentRecharge] =
    useState<boolean>(false);

  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const [onShow, setOnShow] = useState(false);
  const [listItems, setListItems] = useState([]);
  const [user, setUser] = useState('');
  const [userSearchList, setUserSearchList] = useState<any[]>([]);
  const [extracts, setExtracts] = useState<any>([]);
  const [userId, setUserId] = useState('');
  const [virtualCBalance, setVirtualCBalance] = useState('...');
  const [limit, setLimit] = useState({
    monthlySpendLimit: formatNumber(
      currencyFormatFloat(0 as number),
      formatOptions,
    ),
    weeklySpendLimit: formatNumber(
      currencyFormatFloat(0 as number),
      formatOptions,
    ),
    dailySpendLimit: formatNumber(
      currencyFormatFloat(0 as number),
      formatOptions,
    ),
    sunday: formatNumber(currencyFormatFloat(0 as number), formatOptions),
    monday: formatNumber(currencyFormatFloat(0 as number), formatOptions),
    tuesday: formatNumber(currencyFormatFloat(0 as number), formatOptions),
    wednesday: formatNumber(currencyFormatFloat(0 as number), formatOptions),
    thursday: formatNumber(currencyFormatFloat(0 as number), formatOptions),
    friday: formatNumber(currencyFormatFloat(0 as number), formatOptions),
    saturday: formatNumber(currencyFormatFloat(0 as number), formatOptions),
  });
  const [onShowDetailsVC, setOnShowDetailsVC] = useState(false);
  const [onShowLimitsDays, setOnShowLimitsDays] = useState(false);
  const { data: users } = useGetEntityClients(entity, !!entity);
  const { data: orders, isLoading: loading } = useGetClientOrders(
    userId,
    !!userId,
  );
  const { data: extractTransactionData } = useGetClientTransactions(
    userId,
    !!userId,
  );
  const { data: client } = useGetClientById(userId, !!userId);
  const { mutateAsync, isLoading: loadingUpdate } = useUpdateVirtualCard();

  const returnStatus = (e: Order) => {
    if (e.isInProgress) {
      return 'em andamento';
    }
    if (e.isPending) {
      return 'Pendente';
    }
    if (e.isCanceled) {
      return 'Cancelado';
    }
    if (e.isDelivered) {
      return 'Entregue';
    }
    if (e.isOnCredit) {
      return 'Crédito';
    }
    return '';
  };

  const checkLimitVC = (vc: LimitsVirtualCardDto) => {
    setLimit({
      monthlySpendLimit: vc?.monthlySpendLimit
        ? formatNumber(
            currencyFormatFloat(vc?.monthlySpendLimit as number),
            formatOptions,
          )
        : locale.t('finance.textLabel'),
      weeklySpendLimit: vc?.weeklySpendLimit
        ? formatNumber(
            currencyFormatFloat(vc?.weeklySpendLimit as number),
            formatOptions,
          )
        : locale.t('finance.textLabel'),
      dailySpendLimit: vc?.dailySpendLimit
        ? formatNumber(
            currencyFormatFloat(vc?.dailySpendLimit as number),
            formatOptions,
          )
        : locale.t('finance.textLabel'),
      sunday: vc?.sunday
        ? formatNumber(currencyFormatFloat(vc?.sunday as number), formatOptions)
        : locale.t('finance.textLabel'),
      monday: vc?.monday
        ? formatNumber(currencyFormatFloat(vc?.monday as number), formatOptions)
        : locale.t('finance.textLabel'),
      tuesday: vc?.tuesday
        ? formatNumber(
            currencyFormatFloat(vc?.tuesday as number),
            formatOptions,
          )
        : locale.t('finance.textLabel'),
      wednesday: vc?.wednesday
        ? formatNumber(
            currencyFormatFloat(vc?.wednesday as number),
            formatOptions,
          )
        : locale.t('finance.textLabel'),
      thursday: vc?.thursday
        ? formatNumber(
            currencyFormatFloat(vc?.thursday as number),
            formatOptions,
          )
        : locale.t('finance.textLabel'),
      friday: vc?.friday
        ? formatNumber(currencyFormatFloat(vc?.friday as number), formatOptions)
        : locale.t('finance.textLabel'),
      saturday: vc?.saturday
        ? formatNumber(
            currencyFormatFloat(vc?.saturday as number),
            formatOptions,
          )
        : locale.t('finance.textLabel'),
    });

    if (
      vc?.monday > 0 ||
      vc?.sunday > 0 ||
      vc?.tuesday > 0 ||
      vc?.wednesday > 0 ||
      vc?.thursday > 0 ||
      vc?.friday > 0 ||
      vc?.saturday > 0
    ) {
      setOnShowLimitsDays(true);
    }
  };

  const returnType = (rechargeItem: ExtractTransaction) => {
    if (
      rechargeItem.status === '13' &&
      !rechargeItem.is_manual_recharge &&
      !rechargeItem.is_reimbursement
    ) {
      return 'Chargeback';
    }
    if (
      rechargeItem.status === '5' &&
      !rechargeItem.is_manual_recharge &&
      !rechargeItem.is_reimbursement
    ) {
      return 'Em Disputa';
    }
    if (
      rechargeItem.status === '15' &&
      !rechargeItem.is_manual_recharge &&
      !rechargeItem.is_reimbursement
    ) {
      return 'Devolução de Contestação';
    }
    if (
      rechargeItem.status === '6' &&
      !rechargeItem.is_manual_recharge &&
      !rechargeItem.is_reimbursement
    ) {
      return 'Estornado';
    }
    if (rechargeItem.is_manual_recharge) {
      return 'Recarga Manual';
    }
    if (rechargeItem.is_reimbursement) {
      return 'Reembolso';
    }
    if (rechargeItem.is_recurrent) {
      return 'Recarga Cartão Recorrente';
    }
    if (rechargeItem.payment_method === '2' && !rechargeItem.is_recurrent) {
      return 'Recarga Cartão Única';
    }
    if (
      rechargeItem.payment_method === '6' &&
      !rechargeItem.is_recurrent &&
      !rechargeItem.is_manual_recharge &&
      !rechargeItem.is_reimbursement
    ) {
      return 'Recarga Pix';
    }
    if (
      rechargeItem.payment_method === '4' &&
      !rechargeItem.is_recurrent &&
      !rechargeItem.is_manual_recharge &&
      !rechargeItem.is_reimbursement
    ) {
      return 'Transferência';
    }
    if (
      rechargeItem.payment_method === '5' &&
      !rechargeItem.is_recurrent &&
      !rechargeItem.is_manual_recharge &&
      !rechargeItem.is_reimbursement
    ) {
      return 'Saldo Removido';
    }
    return '';
  };

  const returnlistItems = (iditem?: string) => {
    const orderFilter = orders?.filter((order: any) => order.id === iditem);
    orderFilter?.forEach((item: any) => {
      const itemsOrders = item.items?.map((e: any) => ({
        produto: e.product.name,
        quantidade: e.quantity.toString(),
        valor: formatNumber(
          currencyFormatFloat(e.quantity === 0 ? 0 : (e.unitPrice as number)),
          formatOptions,
        ),
      }));
      setListItems(itemsOrders);
    });
    setOnShow(true);
  };

  const close = () => {
    setOnShow(false);
    setOnShowDetailsVC(false);
  };

  const formatList = () => {
    const list = users?.map((r: any) => ({
      label: r.name,
      value: r.id,
      isActive: r.isActive,
    }));

    const listSorted = list.sort((a: any, b: any) =>
      a.label.localeCompare(b.label),
    );

    const filteredList =
      isActiveInactive === 'ativo'
        ? listSorted.filter((p: any) => p.isActive)
        : list.filter((p: any) => !p.isActive);

    setUserSearchList(filteredList);
  };

  const returnOrdersUser = (id: string) => {
    setUserId(id);
  };

  const ordersFormatted = () => {
    let currentBalance = 0;

    const ordersWithDateFormatted =
      orders?.map(order => {
        const date = new Date(order.createdAt);
        const formattedDate = formatDateLocal('pt-BR').format(date);
        const deliveredAt = new Date(order.deliveredAt);
        let formattedDeliveredAt = formatDateLocal('pt-BR').format(
          deliveredAt,
        );
        if (order.deliveredAt === null) {
         formattedDeliveredAt = '';
        }

        return {
          ...order,
          isDetalhes: true,
          type: 'Pedido',
          id: order.id,
          user: order.clientName,
          total: formatNumber(
            currencyFormatFloat(order.totalAmount as number),
            formatOptions,
          ),
          status: returnStatus(order),
          balance: '',
          data: formattedDate,
          createdAtFormatted: date,
          deliveredAt: formattedDeliveredAt,
        };
      }) || [];

    const ordersWithDateFormattedGrouped =
      ordersWithDateFormatted?.reduce((acc: any, item) => {
      const date = new Date(item.createdAt);
      const formattedDate = formatDateLocal('pt-BR').format(date);
      if (!acc[formattedDate]) {
        acc[formattedDate] = {
        items: [],
        balance: 0,
        netTransactions: 0,
        netRecharges: 0,
        };
      }

      acc[formattedDate].items.push({
        ...item,
        deliveredAt: item.deliveredAt ? item.deliveredAt : '',
      });
      acc[formattedDate].netTransactions += item.totalAmount;
      return acc;
      }, {}) || {};
  
    extractTransactionData?.forEach(recharge => {
      const date = new Date(recharge.date_created);
      const formattedDate = formatDateLocal('pt-BR').format(date);

      const rechargeItem: ExtractTransaction = {
        id: recharge.transaction_id,
        type: returnType(recharge),
        user: recharge.client_name || '',
        total: formatNumber(
          currencyFormatFloatNagative(recharge.transaction_amount as number),
          formatOptions,
        ),
        transaction_amount: 0,
        status: recharge.status === '1' &&  recharge.payment_method === '6' ? 'Pendente' : 'Realizado',
        balance: formatNumber(
          currencyFormatFloat(recharge.transaction_amount as number),
          formatOptions,
        ),
        data: formattedDate,
        createdAtFormatted: formattedDate,
        totalAmount: recharge.transaction_amount,
        createdAt: formattedDate,
        isRecharge: true,
        date_created: '',
        items: [
          {
            id: recharge.transaction_id || '',
            createdAt: recharge.date_created,
            isCanceled: false,
            totalAmount: recharge.transaction_amount || 0,
            quantity: 1,
            unitPrice: recharge.transaction_amount || 0,
            product: {
              id: recharge.transaction_id || '',
              cost: 0,
              description: 'recharge',
              isActive: true,
              name: 'recharge',
              picture: '',
              isHighlighted: false,
              price: recharge.transaction_amount,
              productCategory: {
                name: 'recharge',
                isActive: false,
                picture: '',
                id: recharge.transaction_id || '',
              },
              quantity: 1,
              promotionPrice: 0,
              promotionStatus: false,
            },
          },
        ],
      };

      if (!ordersWithDateFormattedGrouped[formattedDate]) {
        ordersWithDateFormattedGrouped[formattedDate] = {
          items: [],
          balance: 0,
          netTransactions: 0,
          netRecharges: 0,
        };
      }
      ordersWithDateFormattedGrouped[formattedDate].items.push(rechargeItem);
      if(recharge.status === '3' || recharge.status === '15' ){
        ordersWithDateFormattedGrouped[formattedDate].netRecharges +=
        rechargeItem.totalAmount;
      }
    });

    const keys = Object.keys(ordersWithDateFormattedGrouped || {});
    let listSorted: any = [];

    const convertToSortableNumber = (dateString: any) => {
      const [date, time] = dateString.split(', ');
      const [day, month, year] = date.split('/');
      const [hour, minute, second] = time.split(':');

      // Use o construtor Date para criar um objeto Date
      const formattedDate = new Date(
        `${year}-${month}-${day}T${hour}:${minute}:${second}`,
      );

      // Formate a data para o formato brasileiro
      formattedDate.toLocaleString('pt-BR');

      // Use o método getTime() para obter o valor numérico
      return formattedDate.getTime();
    };

    listSorted = keys.sort((a, b) => {
      const dateA = convertToSortableNumber(a);
      const dateB = convertToSortableNumber(b);
      return dateB - dateA;
    });

    listSorted.reverse().forEach((label: any) => {
      currentBalance += ordersWithDateFormattedGrouped[label].netRecharges;
      currentBalance -= ordersWithDateFormattedGrouped[label].netTransactions;

      ordersWithDateFormattedGrouped[label].items.forEach((item: Order) => {
        // eslint-disable-next-line no-param-reassign
        item.balance = formatNumber(
          currencyFormatFloat(currentBalance as number),
          formatOptions,
        );
        setVirtualCBalance(
          formatNumber(
            currencyFormatFloat(currentBalance as number),
            formatOptions,
          ),
        );
      });
    });

    const arrayFinal: any = [];
    keys
      .toReversed()
      .map((key: string) =>
        ordersWithDateFormattedGrouped[key].items.forEach(
          (item: ExtractTransaction) => arrayFinal.push(item),
        ),
      );

    setExtracts(arrayFinal);
  };

  useEffect(() => {
    if (users?.length) {
      formatList();
    }
  }, [orders, extractTransactionData, users]);

  useEffect(() => {
    if (client?.virtualCard) {
      checkLimitVC(client?.virtualCard);
    }
    if (client?.virtualCard) {
      setIsActiveRecurrentRecharge(client?.virtualCard.isRecurrent);
    }
  }, [client]);

  useEffect(() => {
    if (orders?.length || extractTransactionData?.length) {
      ordersFormatted();
    }
  }, [orders, extractTransactionData, userId]);

  useEffect(() => {
    if (users?.length) {
      formatList();
    }
  }, [isActiveInactive]);

  const updateVirtualCard = async (status: boolean) => {
    if (client.virtualCard) {
      try {
        const response = await mutateAsync({
          id: client.virtualCard.id,
          isRecurrent: status,
        });
        if (response.ok) {
          setIsActiveRecurrentRecharge(status);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return {
    loading,
    extracts,
    userSearchList,
    user,
    listItems,
    onShow,
    adminData,
    entityData,
    virtualCBalance,
    onShowLimitsDays,
    limit,
    onShowDetailsVC,
    isActiveInactive,
    isActiveRecurrentRecharge,
    client,
    loadingUpdate,
    setIsActiveRecurrentRecharge,
    updateVirtualCard,
    returnlistItems,
    returnOrdersUser,
    setUser,
    setUserId,
    close,
    setOnShowDetailsVC,
    setIsActiveInactive,
  };
};
