import { StyleSheet, Platform } from 'react-native';
import { colors } from '../../../consts';
import { typography } from '../../../styles';

export const datatableBodyStyles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: colors.datatableBody.border,
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 25,
  },
  column: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
  },
  typeofStringHover: {
    ...typography.p3,
    fontWeight: '400',
    color: colors.datatableBody.text,
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
  },
  typeofString: {
    ...typography.p3,
    fontWeight: '400',
    color: colors.datatableBody.text,
  },
  typeofStringDiff: {
    ...typography.p3,
    fontWeight: '400',
    color: colors.errorMessage,
  },
  typeofStringDiff2: {
    ...typography.p3,
    fontWeight: '400',
    color: '#FFD700',
  },
  typeofStringDetalhes: {
    display: 'flex',
    justifyContent: 'flex-start',
    ...typography.p3,
    fontWeight: '200',
    color: colors.datatableHeader.label,
  },
  editBtn: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  imageWrapper: {
    width: 20,
    height: 20,
  },
  imageAsset: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
  },
});
